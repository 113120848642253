import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";
import { isActiveFeature } from '../utils/helpers.js';

class AddContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedHcps: [],
      selectedMas: [],
      selectedHcpPrimary: 0,
      selectedMaPrimary: 0,
      hasChangesToSave: false, // disable Save btn or not
      savedContact: [],
      email: '',
      hcp_email_1: '',
      hcp_email_2: '',
      hcp_email_3: '',
      hcp_email_4: '',
      hcp_phone: '',
      hcp_phone_1: '',
      hcp_phone_2: '',
      hcp_phone_3: '',
      hcp_phone_4: '',
      firstName: '',
      lastName: '',
      practice: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      errorMessage: ""
    };
  }

  componentDidMount = () => {};

  handleChange = (type, event) => {
    const value = event.target.value;
    this.setState({ [type]: value })
  }

  checkValidForm = () => {
    if(this.state.firstName.trim() !== '' && this.state.lastName.trim() !== '' && this.state.email.trim() !== '' && 
      !isNaN(this.state.hcp_phone) && !isNaN(this.state.hcp_phone_1) && !isNaN(this.state.hcp_phone_2) && !isNaN(this.state.hcp_phone_3) && !isNaN(this.state.hcp_phone_4)) {
      return true;
    }
    return false;
  }

  saveContactEdits = () => {
    if(this.checkValidForm()) {
      const that = this;
      const apiUrl = config.get("apiUrl");
      const token = localStorage.getItem("access_token");
      let bodyFormData = new FormData();
      bodyFormData.set("hID", "addContact");
      bodyFormData.set("uID", this.props.uId);

      const contactToSave = {
        email: this.state.email,
        toEmail: this.state.email,
        hcp_email_1: this.state.hcp_email_1,
        hcp_email_2: this.state.hcp_email_2,
        hcp_email_3: this.state.hcp_email_3,
        hcp_email_4: this.state.hcp_email_4,
        hcp_phone: this.state.hcp_phone,
        hcp_phone_1: this.state.hcp_phone_1,
        hcp_phone_2: this.state.hcp_phone_2,
        hcp_phone_3: this.state.hcp_phone_3,
        hcp_phone_4: this.state.hcp_phone_4,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        practice: this.state.practice,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip
      };
      bodyFormData.set("details", JSON.stringify(contactToSave));

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if(response.data.status === "success") {
            contactToSave['id'] = response.data.contactId;
            that.props.handleClose(true, contactToSave);
          } else {
            this.setState({errorMessage: "There was an error saving this contact."});
          }
        })
        .catch(function (response) {
          that.setState({errorMessage: "There was an error saving this contact."});
        });
    } else {
      this.setState({errorMessage: 'Some required fields are blank or invalid.'});
    }
  }

  render() {
    const hcpEmails = [];
    const hcpPhoneNumbers = [];
    
    for (let i = 0; i < 4; i++) {
      let inputClass = "app__contact__input-text-email";
      if (i === 0) {
        inputClass += " primary";
      }

      let hcpVal = i === 0 ? this.state.email : this.state["hcp_email_" + i];
      let hcpPhone = i === 0 ? this.state.hcp_phone : this.state["hcp_phone_" + i];

      hcpEmails.push(
        <label
          className="app__contact__input-text__label"
          key={"hcp-email-" + i}
        >
          <input
            className={inputClass}
            type="text"
            id={"hcp_email_" + i}
            value={
              hcpVal
            }
            placeholder={i === 0 ? 'Primary Email*' : ''}
            onChange={(event) => {
              i === 0 ? this.handleChange('email', event) : this.handleChange("hcp_email_" + i, event)
            }}
          ></input>
        </label>
      );

      hcpPhoneNumbers.push(
        <label
          className="app__contact__input-text__label"
          key={"hcp-phone-" + i}
        >
          <input
            className="app__contact__input-text-email"
            type="text"
            id={"hcp_phone+" + i}
            value={
              hcpPhone
            }
            placeholder={i === 0 ? 'Primary Phone*' : '10 Digit Phone'}
            onChange={(event) => {
              i === 0 ? this.handleChange('hcp_phone', event) : this.handleChange("hcp_phone_" + i, event)
            }}
            maxLength={10}
          ></input>
        </label>
      );
    }

    return (
      <div>
      <div className="app__modal contact__modal title">
        <div className="app__modal__container">
          <p>Add Contact</p>
        </div>
      </div>
      <div className="app__modal contact__modal">
          <div className={!isActiveFeature('sms_sends') ? `app__modal__container no_sms` : `app__modal__container`}>
            <button
              className="close"
              onClick={(event) => {
                this.props.handleClose(false, this.state.savedContact);
              }}
            >
              <i className="fa fa-close"></i>
            </button>

            <div className="app__contact__info-wrapper">
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text required"
                  type="text"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={(event) => {
                    this.handleChange("firstName", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">
                  First Name*
                </div>
              </label>
              <label className="app__contact__input-text__label required">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={(event) => {
                    this.handleChange("lastName", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Last Name*
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="practice"
                  value={this.state.practice}
                  onChange={(event) => {
                    this.handleChange("practice", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Practice
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="address"
                  value={this.state.address}
                  onChange={(event) => {
                    this.handleChange("address", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Address
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="city"
                  value={this.state.city}
                  onChange={(event) => {
                    this.handleChange("city", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">City</div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text state"
                  type="text"
                  id="state"
                  value={this.state.state}
                  onChange={(event) => {
                    this.handleChange("state", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">
                  State
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text zip"
                  type="text"
                  id="zip"
                  value={this.state.zip}
                  onChange={(event) => {
                    this.handleChange("zip", event)
                  }}
                ></input>
                <div className="app__contact__input-text__label-text">Zip</div>
              </label>
            </div>

            <div className="app__contact__add-edit-wrapper">
              
              <div className="app__contact__email-forms">
                <div className="app__contact__hcp-emails">
                  <h4>HCP Email Address</h4>
                  <form>{hcpEmails}</form>
                </div>
              </div>

              {isActiveFeature('sms_sends') && (
                <div className="app__contact__email-forms">
                  <div className="app__contact__hcp-emails">
                    <h4>HCP Mobile Phone Numbers</h4>
                    <form>{hcpPhoneNumbers}</form>
                  </div>
                </div>
              )}

              <div className="app__contact__footer">
                  <p className="app__contact__footer-info">
                    Highlighted field indicates primary HCP email address (required).<br/>
                    {isActiveFeature('sms_sends') && ( <>Phone numbers must be 10 digits to send SMS messages (US Only).</>)}
                  </p>
                  <div className="error">
                      {this.state.errorMessage}
                  </div>
              </div>

              {this.state.activeMenu !== "select" && (
                <button
                  className="app__button app__contact-save"
                  onClick={(event) => {
                    this.saveContactEdits();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
      </div>
      </div>
    );
  }
}

export default AddContactModal;
