export const RESET_STATE = 'RESET_STATE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SELECTED_CONTACTS = 'UPDATE_SELECTED_CONTACTS';
export const UPDATE_SELECTED_VIDEO = 'UPDATE_SELECTED_VIDEO';
export const UPDATE_SCRIPT_OPTIONS = 'UPDATE_SCRIPT_OPTIONS';
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
export const UPDATE_SELECTED_PROGRAMS = 'UPDATE_SELECTED_PROGRAMS';
export const UPDATE_SELECTED_DOCUMENTS = 'UPDATE_SELECTED_DOCUMENTS';
export const UPDATE_SELECTED_IMAGES = 'UPDATE_SELECTED_IMAGES';
export const UPDATE_SELECTED_TEMPLATE_ID = 'UPDATE_SELECTED_TEMPLATE_ID';
export const UPDATE_ACTIVE_BRAND_ID = 'UPDATE_ACTIVE_BRAND_ID';
