import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";
import { isActiveFeature } from '../utils/helpers.js';

class ContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSaved: false, // triggers a warning to the user that changes were made to the DB
      hasChangesToSave: false, // disable Save btn or not
      contact: null,
      origContact: null,
      errorMessage: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      contact: this.props.contact,
      origContact: this.props.contact,
    });
  };

  checkValidForm = () => {
    if(this.state.contact.firstName.trim() !== '' && this.state.contact.lastName.trim() !== '' && this.state.contact.email.trim() !== '' && 
      !isNaN(this.state.contact.hcp_phone) && !isNaN(this.state.contact.hcp_phone_1) && !isNaN(this.state.contact.hcp_phone_2) 
      && !isNaN(this.state.contact.hcp_phone_3) && !isNaN(this.state.contact.hcp_phone_4)) {
      return true;
    }
    return false;
  }

  saveContactEdits = () => {
    if(this.checkValidForm()) {
      const that = this;
      const apiUrl = config.get("apiUrl");
      const token = localStorage.getItem("access_token");
      let bodyFormData = new FormData();
      bodyFormData.set("hID", "updateContactDetails");
      bodyFormData.set("uID", this.props.uId);
      bodyFormData.set("contactId", this.state.contact.id);
      bodyFormData.set("details", JSON.stringify(this.state.contact));
      bodyFormData.set("originalDetails", JSON.stringify(this.state.origContact));

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if(response.data.status === "success") {
            let contact = {...that.state.contact};
            that.setState({ origContact: contact, hasChangesToSave: false, hasSaved: true, errorMessage: ""});
            that.props.handleClose(true, contact);
          } else {
            that.setState({errorMessage: "There was an error saving this contact."});
          }
        })
        .catch(function (response) {
          that.setState({errorMessage: "There was an error saving this contact."});
        });

      } else {
        this.setState({errorMessage: 'Some required fields are blank or invalid.'});
      }
  }

  onHcpAddEdit = (event) => {
    let val = event.target.value;
    let aContact = {...this.state.contact};
    let colName = event.target.id;
    if(colName === 'hcp_email_0') {
      // Primary
      colName = 'email';
      if(val === "") {
        this.setState({errorMessage: "Cannot delete the primary email address."});
        // cannot delete primary email
        return;
      }
    }

    if(colName === 'hcp_phone_0') {
      colName = 'hcp_phone';
    }

    aContact[colName] = val;
    this.setState({ contact: aContact, hasChangesToSave: true, errorMessage: "" });
  }

  render() {
    const hcpEmails = [];
    const hcpPhoneNumbers = [];

    if (this.state.contact) {
      for (let i = 0; i < 4; i++) {
        let inputClass = "app__contact__input-text-email";
        if (i === 0) {
          inputClass += " primary";
        }

        let hcpVal = i === 0 ? this.state.contact.email : this.state.contact["hcp_email_" + i];
        let hcpPhone = i === 0 ? this.state.contact.hcp_phone : this.state.contact["hcp_phone_" + i];
        hcpPhone = hcpPhone === '0' ? '' : hcpPhone;

        hcpEmails.push(
          <label
            className="app__contact__input-text__label"
            key={"hcp-email-" + i}
          >
            <input
              className={inputClass}
              type="text"
              id={"hcp_email_" + i}
              value={
                hcpVal
              }
              placeholder={i === 0 ? 'Primary Email*' : ''}
              onChange={this.onHcpAddEdit}
            ></input>
          </label>
        );

        hcpPhoneNumbers.push(
          <label
            className="app__contact__input-text__label"
            key={"hcp-phone-" + i}
          >
            <input
              className={inputClass}
              type="text"
              id={"hcp_phone_" + i}
              value={
                hcpPhone
              }
              placeholder={i === 0 ? 'Primary Phone*' : '10 Digit Phone'}
              onChange={this.onHcpAddEdit}
              maxLength={10}
            ></input>
          </label>
        );

      }
    }

    return (
      <div>
      <div className="app__modal contact__modal title">
        <div className={`app__modal__container`}>
          <p>Contact Card</p>
        </div>
      </div>
      <div className="app__modal contact__modal">
        {this.state.contact && (
          <div className={!isActiveFeature('sms_sends') ? `app__modal__container no_sms` : `app__modal__container`}>
            <button
              className="close"
              onClick={(event) => {
                this.props.handleClose(this.state.hasSaved, false, this.state.contact);
              }}
            >
              <i className="fa fa-close"></i>
            </button>

            <div className="app__contact__info-wrapper">
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="firstName"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.firstName}
                ></input>
                <div className="app__contact__input-text__label-text">
                  First Name*
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="lastName"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.lastName}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Last Name*
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="practice"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.practice}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Practice
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="address"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.address}
                ></input>
                <div className="app__contact__input-text__label-text">
                  Address
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  id="city"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.city}
                ></input>
                <div className="app__contact__input-text__label-text">City</div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text state"
                  type="text"
                  id="state"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.state}
                ></input>
                <div className="app__contact__input-text__label-text">
                  State
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text zip"
                  type="text"
                  id="zip"
                  onChange={this.onHcpAddEdit}
                  value={this.state.contact.zip}
                ></input>
                <div className="app__contact__input-text__label-text">Zip</div>
              </label>
            </div>

            <div className="app__contact__add-edit-wrapper">
              <div className="app__contact__email-forms">
                <div className="app__contact__hcp-emails">
                  <h4>HCP Email Address</h4>
                  <form>{hcpEmails}</form>
                </div>
              </div>

              {isActiveFeature('sms_sends') && (
                <div className="app__contact__email-forms">
                  <div className="app__contact__hcp-emails">
                    <h4>HCP Mobile Phone Numbers</h4>
                    <form>{hcpPhoneNumbers}</form>
                  </div>
                </div>
              )}

              <div className="app__contact__footer">
                  <p className="app__contact__footer-info">
                    Highlighted field indicates primary HCP email address (required).<br/>
                    {isActiveFeature('sms_sends') && (<>Phone numbers must be 10 digits to send SMS messages (US Only).</>)}
                  </p>
                  <div className="error">
                      {this.state.errorMessage}
                  </div>
              </div>

              {this.state.activeMenu !== "select" && (
                <button
                  disabled={!this.state.hasChangesToSave}
                  className="app__button app__contact-save"
                  onClick={(event) => {
                    this.saveContactEdits();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      </div>
    );
  }
}

export default ContactModal;
