import config from "react-global-configuration";

export default function initConfig(env = "local", brand = "repcaststudio") {
  // MEDINFO.VIDEO
  if (brand === "medinfo") {

    if (env === "local") {
      config.set(
        {
          apiUrl: "http://repcaststudio:8888/api/",
          outlookAppId: "74885008-d0db-4953-9687-8801435147c2",
          outlookRedirectUri: "http://localhost:3000",
          brandName: "medinfo",
          twilioNumber: "(617) 826-8547"
        },
        { freeze: false }
      );
    } else if (env === "development") {
      config.set(
        {
          apiUrl: "https://www.medinfo.video/web/api/",
          outlookAppId: "a76d0fba-f4e4-4375-bf85-d7dddb004518",
          outlookRedirectUri: "https://www.medinfo.video",
          brandName: "medinfo",
          twilioNumber: "(617) 826-8547"
        },
        { freeze: false }
      );
    }  else if (env === "production") {
      config.set(
        {
          apiUrl: "/web/api/",
          outlookAppId: "a76d0fba-f4e4-4375-bf85-d7dddb004518",
          outlookRedirectUri: "https://www.medinfo.video",
          brandName: "medinfo",
          twilioNumber: "(617) 826-8547"
        },
        { freeze: false }
      );
      
    } else {
      throw new Error("Unknown environment, failed to initialize config.");
    }

  // REPCAST.COM
  } else {
    
    if (env === "local") {
      config.set(
        {
          apiUrl: "http://localhost:8181/api/",
          outlookAppId: "74885008-d0db-4953-9687-8801435147c2",
          outlookRedirectUri: "http://localhost:3000",
          brandName: "repcast",
          twilioNumber: "(251) 551-9634"
        },
        { freeze: false }
      );
    } else if (env === "development") {
      // repcast.axonrx.com
      config.set(
        {
          apiUrl: "https://repcast.axonrx.com/web/api/",
          outlookAppId: "e4dbbd96-dbab-44a8-94ed-32cbc86c88e1",
          outlookRedirectUri: "https://repcast.axonrx.com",
          brandName: "repcast",
          twilioNumber: "(251) 551-9634"
        },
        { freeze: false }
      );
    } else if (env === "production") {
      // REPCAST.com
      config.set(
        {
          apiUrl: "/web/api/",
          outlookAppId: "7211f85a-3584-402c-95d4-e4bd6274d938",
          outlookRedirectUri: "https://www.repcast.com",
          brandName: "repcast",
          twilioNumber: "(251) 551-9634"
        },
        { freeze: false }
      );

    } else {
      throw new Error("Unknown environment, failed to initialize config.");
    }
  }
}
