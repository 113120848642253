import React from 'react';
import useUser from '../store/selectors/useUser';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useApiPost from './useApiPost';
import { useDeleteAttachment } from './useDeleteAttachment';

const buildFormData = (user, bId) => {
  let formData = new FormData();
  formData.set('hID', 'getBrandAttachments');
  formData.set('uID', user.id);
  formData.set('bID', bId);
  return formData;
};

export const useAttachments = ({ autoLoad = true } = {}) => {
  const user = useUser();
  const bId = useActiveBrand();
  const { data, loading, handlePost } = useApiPost();
  const { deleteAttachment } = useDeleteAttachment();

  const getAttachments = React.useCallback(props => handlePost({ data: buildFormData(user, bId), ...props }), [bId, user, handlePost]);

  React.useEffect(() => {
    autoLoad && getAttachments();
  }, [autoLoad, getAttachments]);

  return { attachments: data, loading, getAttachments, deleteAttachment };
};
