import React from 'react';

export default function EmailComposerSelectType({ onSelect }) {
  return (
    <div id='recipientsContainer' className={`selectRecipientsContainer`}>
      <div className='app__button-container--large'>
        <div className='app__button-div--large'>
          <button onClick={onSelect(false)} className='app__button app__button--large app__button app__button--multisend'></button>
          <span>MULTI-SEND</span>
        </div>
        <div className='app__button-div--large'>
          <button onClick={onSelect(true)} className='app__button app__button--large app__button--personalized'></button>
          <span>PERSONALIZED</span>
        </div>
      </div>
    </div>
  );
}
