import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import useUser from '../store/selectors/useUser';
import { useAttachments } from '../api/useAttachments';
import ConfirmUploadModal from './ConfirmUploadModal';
import useUploadAttachment from '../api/useUploadAttachment';

export default function AttachmentUpload() {
  const [{ file, filename, title, confirmTitleSave, saveSameTitle, doSubmit, isLoading, uploadedFiles, message, uploadPercentage, bId }, setState] = React.useState({
    file: '',
    filename: '',
    title: '',
    confirmTitleSave: false,
    saveSameTitle: false,
    doSubmit: false,
    isLoading: false,
    uploadedFiles: [],
    message: '',
    uploadPercentage: 0,
    bId: 1
  });
  const fileInput = React.useRef();
  const submitBtn = React.useRef();
  const { id: uId } = useUser();

  const { uploadAttachment } = useUploadAttachment();
  const { attachments, } = useAttachments();
  const brands = attachments.brands || [];

  const onChangeFile = e => {
    setState(prev => ({ ...prev, file: e.target.files[0], filename: e.target.files[0].name }));
  };

  const onChangeTitle = e => {
    setState(prev => ({ ...prev, title: e.target.value }));
  };

  const isFormValid = React.useCallback(() => {
    const allowedExtensions = /(\.mp4|\.pdf\.png\.jpg\.jpeg)$/i;
    if (!allowedExtensions.exec(filename)) {
      setState(prev => ({ ...prev, message: 'Invalid file type. Please upload a .mp4, .pdf, .png, jpg, or .jpeg.' }));
      return false;
    } else if (title.trim() === '') {
      setState(prev => ({ ...prev, message: 'Title cannot be blank.' }));
      return false;
    } else {
      setState(prev => ({ ...prev, message: '' }));
      return true;
    }
  },
    [filename, title]
  );

  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      if (isFormValid) {
        uploadAttachment({
          uId,
          bId,
          title,
          filename,
          file,
          saveSameTitle,
          onProgress: progressEvent => {
            const perc = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            setState(prev => ({ ...prev, uploadPercentage: perc }));
          },
          onSuccess: res => {
            setTimeout(() => setState(prev => ({ ...prev, uploadPercentage: 0 })), 1000);

            if (res.data.status === 'warning' && res.data.code === 'Title exists') {

              setState(prev => ({ ...prev, confirmTitleSave: true, doSubmit: false, isLoading: false, saveSameTitle: false }));

            } else if (res.data.status === 'success') {
              const newFiles = [
                ...uploadedFiles,
                {
                  name: title,
                  path: filename
                }
              ];

              fileInput.current.value = '';
              setState(prev => ({ ...prev, title: '', file: '', filename: '', doSubmit: false, isLoading: false, message: '', uploadedFiles: newFiles, saveSameTitle: false }));

            } else if (res.data.status === 'fail') {
              setState(prev => ({ ...prev, doSubmit: false, isLoading: false, message: res.data.code, saveSameTitle: false }));
            } else {
              setState(prev => ({ ...prev, doSubmit: false, isLoading: false, message: 'There was a problem uploading this item.', saveSameTitle: false }));
            }
          },
          onError: () => {
            setState(prev => ({ ...prev, message: 'There was a problem uploading this item.' }));
          }
        })
      }
    },
    [uId, bId, title, filename, file, saveSameTitle]
  );

  React.useEffect(() => {
    if (doSubmit) {
      submitBtn.current.click();
    }
  }, [saveSameTitle]);

  const handleConfirmTitleSave = React.useCallback(
    confirmed => {
      if (confirmed) {
        setState(prev => ({ ...prev, confirmTitleSave: false, saveSameTitle: true, doSubmit: true }));

      } else {
        setState(prev => ({ ...prev, confirmTitleSave: false, saveSameTitle: false }));
      }
    },
    [setState, submitBtn]
  );

  return (
    <div className='app__attachments'>
      <ConfirmUploadModal isOpen={confirmTitleSave} onConfirm={handleConfirmTitleSave} />
      <span className='app__attachments__prompt'>
        <div className='app__attachments__standalone-header'>
          <h4>File Upload</h4>
          <p>Upload your PDFs and videos to be available in your gallery.</p>
        </div>
      </span>

      <div>
        <div className="upload__message">{message ? <>{message}</> : null}</div>
        <form className="app__upload__form" onSubmit={onSubmit}>
          <input
            type='file'
            className='app__attachments__filename'
            id='customFile'
            ref={fileInput}
            onChange={onChangeFile}
            disabled={isLoading}
          />

          <input
            type='text'
            className='app__attachments__file_title'
            id='customFileTitle'
            value={title}
            placeholder='Document/Video/Image Title'
            onChange={onChangeTitle}
            disabled={isLoading}
          />

          <select className="app__attachments__brand"  onChange={e => {
            setState(prev => ({ ...prev, bId: e.target.value}));
          }}>
            {brands.map((brand, index) => (
              <option className='unselectable' value={brand.bID} key={index}>
                {brand.brandname}
              </option>
            ))}
          </select>

          <input
            type='submit'
            value='Upload'
            className='upload_submit'
            ref={submitBtn}
            disabled={isLoading}
          />

        </form>

        <div className="app__attachments__progress">
          <CircularProgressbar value={uploadPercentage} text={`${uploadPercentage}%`} styles={buildStyles({
            textColor: "#199dde",
            pathColor: "#199dde",
            trailColor: "white"
          })} />
        </div>

        {uploadedFiles.map((row, key) => (
          <div className='uploaded_attachment' key={"uploaded-item-" + key}>
            <span className='uplooad_notify'>Your file has been uploaded: </span>
            <span>{row.name} ({row.path})</span>
          </div>
        ))}
      </div>


    </div>
  );
}
