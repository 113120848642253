import React from 'react';
import VideoGallery from './VideoGallery';
import CustomRecorder from './CustomRecorder';
import ScriptGrid from './ScriptGrid';
import AllAttachments from './AllAttachments';
import Recipients from './Recipients';
import EditTemplate from './EditTemplate';
import useUpdateSelectedVideo from '../store/actions/useUpdateSelectedVideo';
import useUpdateSelectedPrograms from '../store/actions/useUpdateSelectedPrograms';
import useUpdateSelectedDocuments from '../store/actions/useUpdateSelectedDocuments';
import useUpdateSelectedImages from '../store/actions/useUpdateSelectedImages';
import useUpdateScriptOptions from '../store/actions/useUpdateScriptOptions';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useSelectedContacts from '../store/selectors/useSelectedContacts';
import useSelectedVideo from '../store/selectors/useSelectedVideo';
import useScriptDetails from '../store/selectors/useScriptDetails';
import useUpdateLayout from '../store/actions/useUpdateLayout';
import useSelectedDetails from '../store/selectors/useSelectedDetails';
import useSendEmail from '../api/useSendEmail';
import EmailModal from './EmailModal';
import useUser from '../store/selectors/useUser';
import EmailComposerHeader from './EmailComposerHeader';
import EmailComposerFooter from './EmailComposerFooter';
import EmailComposerSelectType from './EmailComposerSelectType';
import { defaultScriptDetails } from '../store/reducers/layout';
import EmailComposerSelectVideoType from './EmailComposerSelectVideoType';
import EmailComposerSelectGifType from './EmailComposerSelectGifType';
import useScheduleEmail from '../api/useScheduleEmail';
import useEmailModal from '../store/selectors/useEmailModal';
import useStepHistory from '../utils/useStepHistory';
import EmailComposerSelectScriptType from './EmailComposerSelectScriptType';
import ScriptManualEntry from './ScriptManualEntry';
import { isEmpty } from '../utils/helpers';
import useSelectedPrograms from '../store/selectors/useSelectedPrograms';
import useSelectedDocuments from '../store/selectors/useSelectedDocuments';
import useSelectedImages from '../store/selectors/useSelectedImages';
import useResetState from '../store/actions/useResetState';
import { isActiveFeature } from '../utils/helpers.js';

const defaultState = {
  personalized: undefined,
  animateGif: undefined
};

const getNextStep = step => {
  let newStep = (step === 4 || step === 9) && !isActiveFeature('attachments')
    ? 10 // if video selected from video gallery, jump to the end
    : step === 4 && isActiveFeature('attachments') ? 9 : step + 1;

    return newStep;
}

export default function EmailComposer() {
  const [{ animateGif, personalized }, setState] = React.useState(defaultState);

  const { id: uId } = useUser();
  const bId = useActiveBrand();
  const updateSelectedVideo = useUpdateSelectedVideo();
  const updateScriptOptions = useUpdateScriptOptions();
  const updateSelectedDocuments = useUpdateSelectedDocuments();
  const updateSelectedPrograms = useUpdateSelectedPrograms();
  const updateSelectedImages = useUpdateSelectedImages();
  const { selectedContacts, updateSelectedContacts } = useSelectedContacts();
  const selectedVideo = useSelectedVideo();
  const scriptDetails = useScriptDetails();
  const selectedDetails = useSelectedDetails();
  const selectedPrograms = useSelectedPrograms().selectedPrograms;
  const selectedDocuments = useSelectedDocuments().selectedDocuments;
  const selectedImages = useSelectedImages().selectedImages;
  const { sendingEmail, sendEmail } = useSendEmail();
  const { schedulingEmail, scheduleEmail } = useScheduleEmail();
  const updateLayout = useUpdateLayout();
  const resetState = useResetState();

  const { step, onNext, onReset, handleBack } = useStepHistory({ getNextStep });

  const { handleOpenEmailModal } = useEmailModal();

  React.useEffect(() => {
    if(isActiveStep([1, 2, 3])) {
      console.log("reset video, script and attachments");
      updateSelectedVideo(null);
      updateScriptOptions(defaultScriptDetails);
      updateSelectedDocuments([]);
      updateSelectedPrograms([]);
      updateSelectedImages([]);
    }
    if(isActiveStep(1)) {
      updateSelectedContacts([]);
    }
    if(isActiveStep([4, 5, 6, 7, 8])) {
      updateSelectedDocuments([]);
      updateSelectedPrograms([]);
      updateSelectedImages([]);
    }
  }, [step]);

  const handleReset = React.useCallback(() => {
    setState(defaultState);
    updateSelectedVideo(null);
    updateSelectedDocuments([]);
    updateSelectedPrograms([]);
    updateSelectedImages([]);
    updateScriptOptions(defaultScriptDetails);
    updateSelectedContacts([]);
    onNext({ nextStep: 1 });
  }, [onReset, setState, updateSelectedVideo, updateScriptOptions, updateSelectedDocuments, updateSelectedPrograms, updateSelectedImages]);

  const onSendEmail = React.useCallback(() => {
    !sendingEmail &&
      sendEmail({
        uId,
        bId,
        selectedVideo,
        selectedContacts,
        selectedDetails,
        selectedPrograms,
        selectedDocuments,
        selectedImages,
        onSuccess: result => {
          const results = Array.isArray(result?.data?.result) ? result?.data?.result : [result?.data?.result]; //Ensure an array of results
          const successEmails = results.filter(result => result.toLowerCase().includes('=> success') && !result.toLowerCase().includes('ok => success'));
          const failedEmails = results.filter(result => result.toLowerCase().includes('=> fail'));
          handleOpenEmailModal(true, 'send', successEmails, failedEmails)();
          resetState();
        }
      });
  }, [uId, bId, selectedVideo, selectedContacts, selectedDetails, selectedPrograms, selectedDocuments, selectedImages, sendingEmail, sendEmail, handleOpenEmailModal, resetState]);

  const onScheduleEmail = React.useCallback(
    ({ timeZone, dateTime }) => {
      !schedulingEmail &&
        scheduleEmail({
          uId,
          bId,
          selectedVideo,
          selectedContacts,
          selectedDetails,
          selectedPrograms, 
          selectedDocuments,
          selectedImages,
          timeZone,
          dateTime,
          onSuccess: result => {
            handleOpenEmailModal(true, 'schedule')();
            resetState();
          }
        });
    },
    [uId, bId, selectedVideo, selectedContacts, selectedDetails, selectedPrograms, selectedDocuments, selectedImages, schedulingEmail, scheduleEmail, handleOpenEmailModal, resetState]
  );

  const handleSelectType = React.useCallback(
    (personalized = false) =>
      () => {
        onNext({ nextStep: 2 });
        setState(prev => ({ ...prev, personalized }));
      },
    [onNext, setState]
  );

  const handleSelectVideoType = React.useCallback(
    type => () => {
      if (type === 'skip') {
        updateSelectedVideo(0);
        onNext({ nextStep: 9 });

      } else if (type === 'select') {
        // Removed selected video in case user went back
        updateSelectedVideo(null);
        updateScriptOptions(defaultScriptDetails);
        onNext({ nextStep: 4 });

      } else {

        if(isActiveFeature('custom_script')) {
          onNext({ nextStep: 5 }); // Create video
        } else {
          updateLayout(prev => ({ ...prev, scriptDetails: { ...prev.scriptDetails, type: 'select' } }));  
          onNext({ nextStep: 6 });
        }
        
      }
    },
    [onNext, updateSelectedVideo, updateScriptOptions, updateLayout]
  );

  const handleSelectGifType = React.useCallback(
    gifType => () => {
      onNext({ nextStep: 8 });
      setState(prev => ({ ...prev, animateGif: gifType === 'animated' }));
    },
    [setState, onNext]
  );

  const handleCompleteRecord = React.useCallback(
    newId => {
      updateSelectedVideo(newId);
      if(isActiveFeature('attachments')) {
        onNext({ nextStep: 9 });
      } else {
        onNext({ nextStep: 10 });
      }
    },
    [updateSelectedVideo, onNext]
  );

  const isActiveStep = React.useCallback(steps => (Array.isArray(steps) ? steps.find(s => s === step) : steps === step), [step]);

  const hideBack = isActiveStep([1, 8, 11]);

  const hideNext =
    isActiveStep([1, 3, 5, 7, 8, 10, 11]) ||
    (step === 2 && selectedContacts.length === 0) ||
    (step === 4 && selectedVideo === null) ||
    (step === 6 &&
      scriptDetails.type !== 'manual' &&
      (scriptDetails.intro === '' || scriptDetails.contentA === '' || scriptDetails.contentB === '' || scriptDetails.closing === '')) ||
    (step === 6 &&
      scriptDetails.type === 'manual' &&
      isEmpty(scriptDetails.intro) &&
      isEmpty(scriptDetails.contentA) &&
      isEmpty(scriptDetails.contentB) &&
      isEmpty(scriptDetails.closing)) ||
    (step === 9 && selectedVideo === 0 && selectedPrograms.length === 0 && selectedDocuments.length === 0 && selectedImages.length === 0);

  const isPending = sendingEmail || schedulingEmail;

  const hasPhoneNumbers = (selectedContacts.find(sc => !(isNaN(sc.toEmail))) ? true : false);
  const hasEmailAddresses = (selectedContacts.find(sc => (isNaN(sc.toEmail))) ? true : false);

  return (
    <>
      <EmailModal isPending={isPending} onClose={handleReset} />
      <EmailComposerHeader step={step} onBack={handleBack} onSendEmail={onSendEmail} onScheduleEmail={onScheduleEmail} disabled={isPending} />
      <div className='composerContainer'>
        {isActiveStep(1) && <EmailComposerSelectType onSelect={handleSelectType} />}
        {isActiveStep(2) && <Recipients singleSelect={personalized} />}
        {isActiveStep(3) && <EmailComposerSelectVideoType onSelect={handleSelectVideoType} />}
        {isActiveStep(4) && <VideoGallery canSelect={true} canDelete={false} />}
        {isActiveStep(5) && <EmailComposerSelectScriptType onNext={onNext} />}
        {isActiveStep(6) && <>{scriptDetails?.type === 'select' ? <ScriptGrid personalized={personalized} brandId={bId}/> : <ScriptManualEntry />}</>}
        {isActiveStep(7) && <EmailComposerSelectGifType onSelect={handleSelectGifType} />}
        {isActiveStep(8) && <CustomRecorder animateGif={animateGif} onBack={handleBack} onComplete={handleCompleteRecord} canSaveToGallery={!personalized} />}
        {isActiveStep(9) && <AllAttachments canSelect={true} canUpload={false} />}
        {isActiveStep([10, 11]) && (
          <div id='templateEditContainer' className={!hasEmailAddresses ? 'templateEditContainer sms-only' : 'templateEditContainer'}>
            <EditTemplate step={step} selectedVideo={selectedVideo} onNext={onNext} hasPhoneNumbers={hasPhoneNumbers} hasEmailAddresses={hasEmailAddresses} />
          </div>
        )}
      </div>
      <EmailComposerFooter step={step} onBack={handleBack} onNext={onNext} hideBack={hideBack} hideNext={hideNext} disableNext={isPending} />
    </>
  );
}
