import React from 'react';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useUser from '../store/selectors/useUser';
import useSelectedTemplateId from '../store/selectors/useSelectedTemplateId';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, bId, templateId, userName, key, videoName, size, type, duration, makeVideoVisible, gifBlob, smallGifBlob}) => {
  let formData = new FormData();
  formData.set('hID', 'saveVideo');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('tID', templateId);
  formData.set('name', userName);
  formData.set('videoName', videoName);
  formData.set('fileName', key);
  formData.set('size', size);
  formData.set('type', type);
  formData.set('runtime', duration);
  formData.set('saveToGallery', makeVideoVisible ? '1' : '0');
  formData.set('gifBlob', gifBlob);
  formData.set('smallGifBlob', smallGifBlob);
  return formData;
};

export default function useSaveVideo() {
  const { loading, handlePost } = useApiPost();
  const { id: uId, name: userName } = useUser();
  const bId = useActiveBrand();
  const templateId = useSelectedTemplateId();

  const saveVideo = React.useCallback(
    ({ key, videoName, size, type, duration, makeVideoVisible, gifBlob, smallGifBlob, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, templateId, userName, key, videoName, size, type, duration, makeVideoVisible, gifBlob, smallGifBlob }),
        ...other
      });
    },
    [uId, bId, templateId, userName, handlePost]
  );

  return { saveVideo, savingVideo: loading };
}
