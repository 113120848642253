import React from 'react';
import Footer from '../Footer';
import useUser from '../../store/selectors/useUser';
import useHandleChangeRoute from '../../utils/useHandleChangeRoute';
import useChangeRoute from '../../utils/useChangeRoute';
import { isEmpty } from '../../utils/helpers';
import useHandleLogoutUser from '../../store/actions/useHandleLogoutUser';
import useActiveBrand from '../../store/selectors/useActiveBrand';
import useSelectedVideo from '../../store/selectors/useSelectedVideo';
import useSelectedPrograms from '../../store/selectors/useSelectedPrograms';
import useSelectedDocuments from '../../store/selectors/useSelectedDocuments';
import useSelectedImages from '../../store/selectors/useSelectedImages';
import useScriptDetails from '../../store/selectors/useScriptDetails';
import useUpdateActiveBrandId from '../../store/actions/useUpdateActiveBrandId';
import config from 'react-global-configuration';

export default function SideBar() {
  const { name, photo, brands } = useUser();
  const handleChangeRoute = useHandleChangeRoute();
  const changeRoute = useChangeRoute();
  const handleLogoutUser = useHandleLogoutUser();
  const updateActiveBrandId = useUpdateActiveBrandId();
  const brandName = config.get('brandName');
  const bId = useActiveBrand();
  const selectedVideo = useSelectedVideo();
  const selectedScript = useScriptDetails();
  const selectedPrograms = useSelectedPrograms().selectedPrograms;
  const selectedDocuments = useSelectedDocuments().selectedDocuments;
  const selectedImages = useSelectedImages().selectedImages;

  React.useEffect(() => {
    // User should have profile photo before sending emails
    if (isEmpty(photo) || photo.endsWith('/') || photo.endsWith('\\') || photo.indexOf('shadow.png') > -1) {
      changeRoute('/Profile');
    }
  }, [photo, changeRoute]);

  React.useEffect(() => {
    console.log("Sidebar ", selectedVideo, selectedScript, selectedDocuments, selectedPrograms, selectedImages);
  }, [selectedVideo, selectedScript, selectedPrograms, selectedDocuments, selectedImages]);

  return (
    <div className='app__sidebar sidebar'>
      <div className={'sidebar__logo ' + brandName}>
        <img src='/img/axonrx_sidebar_logo.jpeg' alt='Axonrx' />
      </div>
      <div className='app__header__profile app__header__profile--sidebar'>
        <div
          id='profileImage'
          className='app__icon app__header__icon'
          onClick={handleChangeRoute('/Profile')}
          style={{ backgroundImage: `url(${photo})` }}
        ></div>
        <span className='app__header__username'>
          {name}
          <br/>
          <div onClick={handleLogoutUser} id='headerLogout' style={{ color: '#2699fb', cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}>
            Log Out
          </div>
          <select className="app__attachments__brand" onChange={e => {
            updateActiveBrandId(e.target.value);
          }} disabled={selectedVideo || selectedDocuments.length > 0 || selectedPrograms.length > 0 || selectedImages.length > 0 || (selectedScript.type === "select" && selectedScript.intro !== '')}>
            {brands.map((brand, index) => (
              <option className='unselectable' value={brand.bID} key={index}>
                {brand.brandname}
              </option>
            ))}
          </select>
        </span>
      </div>
      <div className='sidebar__footer'>
        <Footer />
      </div>
    </div>
  );
}
