import React, { useState } from 'react';
import config from 'react-global-configuration';
import axios from 'axios';
import useUser from '../store/selectors/useUser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { getContacts } from '../GraphService';

export default function OutlookConnect({ onUpdateContacts }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState('');
  const user = useUser();

  const msalConfig = {
    auth: {
      clientId: config.get('outlookAppId'),
      redirectUri: config.get('outlookRedirectUri')
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  };

  const getMsalContacts = async () => {
    const msal = new PublicClientApplication(msalConfig);

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal, {
      account: msal.getActiveAccount(),
      scopes: ['contacts.read'],
      interactionType: InteractionType.Popup
    });

    await msal
      .loginPopup({
        scopes: ['contacts.read'],
        prompt: 'select_account'
      })
      .then(value => {
        msal.setActiveAccount(value.account);
      });

    const response = await getContacts(authProvider);

    setContacts(response.value);
    setShowConfirm(true);
  };

  const saveContacts = () => {
    const apiUrl = config.get('apiUrl');
    const token = localStorage.getItem('access_token');
    let bodyFormData = new FormData();
    bodyFormData.set('hID', 'saveContacts');
    bodyFormData.set('uID', user.id);
    bodyFormData.set('contacts', JSON.stringify(contacts));

    axios({
      method: 'post',
      url: apiUrl + 'index.php',
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(function (response) {
        if (response.data.status === 'success') {
          setError('');
          setShowConfirm(false);
          onUpdateContacts(response.data.result);
        } else {
          setError('There was an error saving this contact.');
          setShowConfirm(false);
        }
      })
      .catch(function (response) {
        setError('There was an error saving this contact.');
        setShowConfirm(false);
      });
  };

  return (
    <div className='app__outlook-connect'>
      <div className='app__recipients__connect'>
        <span className="error">{error}</span>
        <button className='app__recipients__connect-btn app__button app__button--secondary' onClick={() => getMsalContacts()}>
          Import Contacts from Outlook
        </button>
      </div>

      {showConfirm && (
        <div>
          <div className='app__modal contact__modal overwrite__modal'>
            <div className='app__modal__container'>
              <span className='app__modal__heading'>Save Contacts?</span>
              <p className='app__modal__text'>You are about to add {contacts.length} contacts. This will replace your existing saved contacts - do you wish to continue?</p>
              <button  className='app__button app__contact-save'
                onClick={event => {
                  setShowConfirm(false);
                }}
              >
                Cancel
              </button>

              <button className='app__button app__contact-save'
                onClick={event => {
                    saveContacts();
                }}
              >
                Yes, Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
