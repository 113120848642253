import { region, accessKeyId, secretAccessKey, vocabularyName } from '../awsConfig';
import { downsampleBuffer, pcmEncode } from './audioUtils';
import v4 from './awsTranscribeSignature'; // to generate our pre-signed URL
const crypto            = require('crypto'); // to sign our pre-signed URL
const marshaller        = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
const util_utf8_node    = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
const mic               = require('microphone-stream'); // collect microphone input as a stream of raw bytes
const diff              = require('diff');

// Converts binary event stream messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

// Constants for global use
const LANGUAGE_CODE = "en-US"
const SAMPLE_RATE = 44100

// Global variables for managing state
const specialty = 'PRIMARYCARE';
const audioType = 'DICTATION';
let inputSampleRate;
let norm_script = "";
let transcription = "";
let socket;
let micStream;
let socketError = false;
let transcribeException = false;

export function startTranscribeWithStream(inputScript, stream) {
    norm_script = inputScript;
    transcription = "";
    streamAudioToWebSocket(stream);
}


export function stopTranscribe() {
    closeSocket();
}

export function compareScript(exclude_words, banned_words) {
    console.log('transcription compare');
    
    // Normalize script and transcription to prepare for comparison
    let output = {};
    output.norm_script = norm_script;
    output.norm_transcript = normalizeText(transcription);
    console.log("---- input script", norm_script);
    console.log("---- transcript", output.norm_transcript);

    // Remove excluded words from input script
    exclude_words = exclude_words.split(',');
    exclude_words.forEach(function(word, index) {
        let regex = new RegExp(word.trim(), 'gi')
        norm_script = norm_script.replace(regex, '')  
    });
   
    // Find percentage difference between script and transcript
    let groups = diff.diffWords(normalizeText(norm_script.toLocaleLowerCase()), output.norm_transcript.toLocaleLowerCase());
    console.log("groups? ", groups);
    let diffResult = createDiff(groups, banned_words);

    output.percent_same = getPercentDiff(groups);
    output.highlighted_transcript = diffResult.highlighted_transcript;
    output.has_banned = diffResult.has_banned;

    return output;
}

function streamAudioToWebSocket(userMediaStream) {
    // Gets mic input from browser via microphone-stream module
    micStream = new mic();
    micStream.on("format", function(data) {
        inputSampleRate = data.SAMPLE_RATE;
    });
    micStream.setStream(userMediaStream);

    // Pre-signed URLs are a way to authenticate a request (or WebSocket connection, in this case)
    // via Query Parameters. Learn more: https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html
    let url = createPresignedUrl();

    // Open WebSocket connection
    socket = new WebSocket(url);
    socket.binaryType = "arraybuffer";

    // Send audio data to WebSocket
    socket.onopen = function() {
        micStream.on('data', function(rawAudioChunk) {
            // Audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
            let binary = convertAudioToBinaryMessage(rawAudioChunk);
            if (socket.readyState === socket.OPEN)
                socket.send(binary);
        }
    )};

    // Handle messages, errors, and close events
    wireSocketEvents();
}


function toggleStartStop(disableStart = false) {
    console.log("togglestartsotp");
}

function showError(message) {
    console.log("showerror " + message);
}

function wireSocketEvents() {
    // Handle inbound messages from Amazon Transcribe
    socket.onmessage = function (message) {
        // Convert the binary event stream message to JSON
        let messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
        let messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
        if (messageWrapper.headers[":message-type"].value === "event") {
            handleEventStreamMessage(messageBody);
        }
        else {
            transcribeException = true;
            showError(messageBody.Message);
            toggleStartStop();
        }
    };

    socket.onerror = function () {
        socketError = true;
        showError('WebSocket connection error. Try again.');
        toggleStartStop();
    };
    
    socket.onclose = function (closeEvent) {
        micStream.pause();
        
        // The close event immediately follows the error event; only handle one.
        if (!socketError && !transcribeException) {
            if (closeEvent.code !== 1000) {
                showError('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
            }
            toggleStartStop();
        }
    };
}

let handleEventStreamMessage = function (messageJson) {
    let results = messageJson.Transcript.Results;

    if (results.length > 0) {
        if (results[0].Alternatives.length > 0) {
            let transcript = results[0].Alternatives[0].Transcript;

            // Fix encoding for accented characters
            transcript = decodeURIComponent(escape(transcript));
            console.log("Real time transcript... " + transcription + transcript + "\n");

            // If this transcript segment is final, add it to the overall transcription
            if (!results[0].IsPartial) {
                console.log("the end?");
                transcription += transcript + "\n";
            }
        }
    }
}

let closeSocket = function () {
    if (socket.readyState === socket.OPEN) {
        micStream.pause();

        // Send an empty frame so that Transcribe initiates a closure of the WebSocket after submitting all transcripts
        let emptyMessage = getAudioEventMessage(Buffer.from(new Buffer([])));
        let emptyBuffer = eventStreamMarshaller.marshall(emptyMessage);
        socket.send(emptyBuffer);
    }
}


function normalizeText(text) {
    // Remove punctuation
    let normalized = text.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()"']/g, "");
    // Remove any awkward spacing caused by removing punctuation
    normalized = normalized.replace(/\s{2,}/g," ");
    return normalized.toLowerCase();
}

function getPercentDiff(groups) {
    let error_length = 0
    let total_length = 0
    
    // Tally up total "error" words and total words in transcription
    for (var i = 0; i < groups.length; i++) {
        const { value, added, removed } = groups[i];
        let len = value.split(" ").length
        if (added || removed) {
            error_length += len
        }
        total_length += len
    }

    // Return as percent accuracy
    return parseInt(((total_length - error_length) / total_length) * 100)
}

function createDiff(groups, banned_words) {
    // Creates diff span between script and transcript
    const bannedColor = "red";
    let hasBanned = false;
    banned_words = banned_words.split(',');

    let comparison = "";
    for (var i = 0; i < groups.length; i++) {
        const { value, added, removed } = groups[i];
        let nodeColor = "";

        if(removed === true) {
            continue;
        }

        if(!added && !removed) {
            nodeColor = "lightgreen";
        } else {
            nodeColor = "";
        }

        comparison = comparison.concat("<span style=\"color: " + nodeColor + ";\">" + value + "</span>");
    }
    // check for banned words
    for (i = 0; i < banned_words.length; i++) {
        let banned_word = banned_words[i].trim().toLowerCase();
        if (comparison.includes(banned_word) && banned_word !== "") {
            hasBanned = true;
            comparison = comparison.replace(banned_word, "<span style=\"color: " + bannedColor + ";\">" + banned_word + "</span>");
        }
    }

    return {
        highlighted_transcript: comparison,
        has_banned: hasBanned
    };
}

function convertAudioToBinaryMessage(audioChunk) {
    let raw = mic.toRaw(audioChunk);
    if (raw == null)
        return;

    // Downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = downsampleBuffer(raw, inputSampleRate, SAMPLE_RATE);
    let pcmEncodedBuffer = pcmEncode(downsampledBuffer);

    // Add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    // Convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}


function getAudioEventMessage(buffer) {
    // Wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}


function createPresignedUrl() {
    let endpoint = "transcribestreaming." + region + ".amazonaws.com:8443";

    // Get a preauthenticated URL that we can use to establish our WebSocket
    return v4.createPresignedURL(
        'GET',
        endpoint,
        '/medical-stream-transcription-websocket',
        'transcribe',
        crypto.createHash('sha256').update('', 'utf8').digest('hex'), {
            'key': accessKeyId,
            'secret': secretAccessKey,
            'sessionToken': '',// TODO: not needed?
            'protocol': 'wss',
            'expires': 15,
            'region': region,
            'query': "language-code=" + LANGUAGE_CODE + "&media-encoding=pcm&sample-rate="
                    + SAMPLE_RATE + "&specialty=" + specialty + "&type=" + audioType + "&vocabulary-name=" 
                    + vocabularyName
        }
    );
}
