import { evalFunc } from '../../utils/helpers';
import {
  RESET_STATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_SELECTED_CONTACTS,
  UPDATE_SELECTED_VIDEO,
  UPDATE_SCRIPT_OPTIONS,
  UPDATE_LAYOUT,
  UPDATE_SELECTED_PROGRAMS,
  UPDATE_SELECTED_DOCUMENTS,
  UPDATE_SELECTED_IMAGES,
  UPDATE_SELECTED_TEMPLATE_ID,
  UPDATE_ACTIVE_BRAND_ID
} from '../constants/action-types';

export const defaultScriptDetails = {
  type: undefined,
  intro: '',
  contentA: '',
  contentB: '',
  closing: ''
};

export const defaultSelectedDetails = {
  type: 'Options',
  subject: '',
  subtext: '',
  greeting: '',
  intro: '',
  close: '',
  sms: '',
  customSms: '',
  customSubject: '',
  customText: '',
};

const initialState = {
  isLoggedIn: false,
  isEmailModalOpen: false,
  isEmailSmsModalOpen: false,
  emailModalType: 'send',
  successEmails: [],
  failedEmails: [],
  user: {},
  activeBrand: '1',
  selectedTemplateId: 0,
  selectedVideo: null,
  selectedGif: null,
  selectedContacts: [],
  selectedEmails: {}, // Info for mapping selectedContacts selected email field
  selectedDetails: defaultSelectedDetails,
  scriptDetails: defaultScriptDetails,
  selectedPrograms: [],
  selectedDocuments: [],
  selectedImages: []
};

function layoutReducer(state = initialState, action) {
  if (action.type === RESET_STATE) {
    return {
      ...state,
      selectedVideo: null,
      selectedGif: null,
      selectedContacts: [],
      selectedEmails: {},
      selectedPrograms: [],
      selectedDocuments: [],
      selectedImages: [],
      selectedDetails: defaultSelectedDetails
    };
  }

  if (action.type === LOGIN) {
    let newUser = {
      email: action.payload.email,
      id: action.payload.id,
      name: action.payload.name,
      phone: action.payload.phone,
      photo: action.payload.photo,
      region: action.payload.region,
      title: action.payload.title,
      transcribe_options: action.payload.transcribe_options,
      isAdmin: action.payload.isAdmin,
      brands: action.payload.brands
    };
    return {
      ...state,
      user: newUser,
      isLoggedIn: true
    };
  }

  if (action.type === LOGOUT) {
    return {
      ...state,
      user: {},
      isLoggedIn: false
    };
  }

  if (action.type === UPDATE_USER) {
    let newUser = Object.assign({}, state.user, {
      name: action.payload.name,
      phone: action.payload.phone,
      photo: action.payload.photo
    });
    return {
      ...state,
      user: newUser
    };
  }

  if (action.type === UPDATE_SELECTED_CONTACTS) {
    return {
      ...state,
      selectedContacts: action.payload
    };
  }

  if (action.type === UPDATE_SELECTED_TEMPLATE_ID) {
    return {
      ...state,
      selectedTemplateId: action.payload
    };
  }

  if (action.type === UPDATE_ACTIVE_BRAND_ID) {
    return {
      ...state,
      activeBrand: action.payload
    };
  }

  if (action.type === UPDATE_SELECTED_VIDEO) {
    return {
      ...state,
      selectedVideo: action.payload
    };
  }
  
  if (action.type === UPDATE_SCRIPT_OPTIONS) {
    return {
      ...state,
      scriptDetails: {
        ...state.scriptDetails,
        ...evalFunc(action.payload)
      }
    };
  }

  if (action.type === UPDATE_LAYOUT) {
    return {
      ...state,
      ...evalFunc(action.payload, state)
    };
  }

  if (action.type === UPDATE_SELECTED_PROGRAMS) {
    return {
      ...state,
      selectedPrograms: action.payload
    };
  }

  if (action.type === UPDATE_SELECTED_DOCUMENTS) {
    return {
      ...state,
      selectedDocuments: action.payload
    };
  }


  if (action.type === UPDATE_SELECTED_IMAGES) {
    return {
      ...state,
      selectedImages: action.payload
    };
  }
  return state;
}

export default layoutReducer;
