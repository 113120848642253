import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from '../api/useApiPost';
import useActiveBrand from '../store/selectors/useActiveBrand';

const mapResponseToData = (response, setState) => {
  if (response.data.status === 'false' || response.data.status === 'fail') {
    setState(prev => ({ ...prev, error: response.data.code, loading: false }));
    console.error('Error: ' + response.data.code);
  } else {
    let newArr = [];
    let r = 0;

    for (r = 0; r < response.data.result.length; r++) {
      let videos = '';
      let attachments = '';
      let events = '';
      let i = 0;

      if (response.data.result[r].videos) {
        for (i = 0; i < response.data.result[r].videos.length; i++) {
          videos += response.data.result[r].videos[i].title;
        }
      }
      if (response.data.result[r].documents) {
        for (i = 0; i < response.data.result[r].documents.length; i++) {
          attachments += 'PDF: ' + response.data.result[r].documents[i].title + '\n';
        }
      }
      if (response.data.result[r].programs) {
        for (i = 0; i < response.data.result[r].programs.length; i++) {
          attachments += 'VIDEO: ' + response.data.result[r].programs[i].title + '\n';
        }
      }
      if (response.data.result[r].images) {
        for (i = 0; i < response.data.result[r].images.length; i++) {
          attachments += '  IMAGE: ' + response.data.result[r].images[i].title + '\n';
        }
      }
      if (response.data.result[r].events) {
        for (i = 0; i < response.data.result[r].events.length; i++) {
          let eventName = response.data.result[r].events[i].eventName;
          if (response.data.result[r].events[i].email_image_watch) {
            eventName = 'opened';
          }
          events += eventName + ':\n' + response.data.result[r].events[i].eventDate + '\n \n';
        }
        events = events.slice(0, -2);
      }

      newArr.push({
        dateSent: response.data.result[r].sentDate,        
        subject: response.data.result[r].email_subject,
        videos: videos,
        attachments: attachments,
        recipient: response.data.result[r].toName + '\n' + response.data.result[r].toEmail,
        activity: events
      });
    }
    return newArr;
  }
};

const buildFormData = ({ uId, brandId, email }) => {
  let formData = new FormData();
  let sDate = new Date(document.getElementById('activityStartDate').value.replace(/-/g, '/'));
  let eDate = new Date(document.getElementById('activityEndDate').value.replace(/-/g, '/'));

  let sDay = sDate.getDate();
  if (sDay.toString().length === 1) {
    sDay = '0' + sDay;
  }
  let eDay = eDate.getDate();
  if (eDay.toString().length === 1) {
    eDay = '0' + eDay;
  }
  let sMonth = sDate.getMonth() + 1;
  if (sMonth.toString().length === 1) {
    sMonth = '0' + sMonth;
  }
  let eMonth = eDate.getMonth() + 1;
  if (eMonth.toString().length === 1) {
    eMonth = '0' + eMonth;
  }

  let startDate = sDate.getFullYear() + '-' + sMonth + '-' + sDay + 'T00:00:00';
  let endDate = eDate.getFullYear() + '-' + eMonth + '-' + eDay + 'T23:59:59';

  formData.set('hID', 'getActivity');
  formData.set('uID', uId);
  formData.set('fromEmail', email);
  formData.set('startDate', startDate);
  formData.set('endDate', endDate);
  formData.set('brandId', brandId);
  return formData;
};

export default function useActivities({ autoLoad = true } = {}) {
  const brandId = useActiveBrand();
  const { id: uId, email } = useUser();

  const { data, handlePost, loading, error } = useApiPost({ mapResponseToData });
  const handleRefresh = React.useCallback(() => handlePost({ data: buildFormData({ uId, brandId, email }) }), [uId, brandId, email, handlePost]);

  React.useEffect(() => {
    autoLoad && handleRefresh();
  }, [autoLoad, handleRefresh]);

  return { data, loading, error, handleRefresh };
}
